<template>
    <div class="content sto-callback-page">
        <div class="content__title text_40_b">Заказ звонка</div>
        <div class="form">
            <transition name="fade" mode="out-in">
                <div v-if="!sended" key="1" class="form__content">
                    <div class="form-group">
                        <div class="form-field form-field--wide">
                            <div class="form-field__label">
                                Ваше имя: <span class="error">{{ errorMessages.name }}</span>
                            </div>
                            <div class="form-field__component">
                                <input class="form-group__input" type="text" required v-model="userName" />
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="form-field form-field--wide">
                            <div class="form-field__label">Ваш телефон:</div>
                            <div class="form-field__component">
                                <vue-phone-number-input
                                    no-flags
                                    :preferred-countries="['RU']"
                                    :translations="phoneTranslations"
                                    v-model="phone"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="form-group form-group--lg-offset">
                        <div class="form-field form-field--centered">
                            <div class="button button--blue" @click="submit()">
                                Отправить
                                <div class="loader" v-if="requestStatus === 'loading'"></div>
                            </div>
                        </div>
                    </div>
                    <div class="text_24_b tac submit-error" v-if="requestStatus === 'error'">
                        Произошла ошибка отправки формы, <br />позвоните по номеру телефона
                        <a href="tel:+78482713013">8 (8482) 713 - 013</a>
                    </div>
                </div>
                <div class="form__content" key="2" v-else>
                    <div class="text_24_b tac">Заявка отправлена, менеджер свяжется с вами в ближайшее время</div>
                </div>
            </transition>
            <div class="form__footer text_12">
                Отправляя данную форму, я даю согласие на
                <a href="https://arlan-auto.ru/public-oferta" target="_blank">обработку персональных данных</a>
            </div>
        </div>
    </div>
</template>
<script>
// import axios from "axios";
export default {
    name: "Index",
    data() {
        const year = new Date().getFullYear();
        const yearsOptions = [];

        for (let index = 1991; index <= year; index++) {
            yearsOptions.push({ name: index.toString(), value: index.toString() });
        }
        return {
            requestStatus: "",
            sended: false,
            errorMessages: {
                name: "",
            },
            userName: "",
            phone: "",
            smartToken: "",
            phoneTranslations: {
                countrySelectorLabel: "Код",
                countrySelectorError: "Ошибка",
                phoneNumberLabel: "Номер телефона",
                example: "Например:",
            },
        };
    },
    methods: {
        showError(field) {
            let message = "заполните это поле";
            if (field == "name") {
                message = "заполните имя";
            }
            this.errorMessages[field] = message;
        },
        async submit() {
            return false;

            // if (!this.userName) {
            //     this.showError("name");
            //     return false;
            // }

            // this.requestStatus = "loading";
            // window.smartCaptcha.execute();
            // setTimeout(async () => {
            //     this.smartToken = window.smartCaptcha.getResponse();
            //     if (!this.smartToken) {
            //         console.error(`не получен токен капчи =>`);
            //         this.requestStatus = "error";
            //         return;
            //     }
            //     axios.defaults.withCredentials = true;
            //     axios
            //         .get("https://api.arlan-auto.ru/api/v1/csrf_token")
            //         .then(() => {
            //             axios
            //                 .post("https://api.arlan-auto.ru/api/v1/amo/create/lead/sto/form-callback", {
            //                     name: this.userName,
            //                     phone: "+7 " + this.phone,
            //                     "smart-token": this.smartToken,
            //                 })
            //                 .then(() => {
            //                     this.sended = true;
            //                     this.requestStatus = "";
            //                 })
            //                 .catch((e) => {
            //                     console.log(`e =>`, e);
            //                     this.requestStatus = "error";
            //                 });
            //         })
            //         .catch((e) => {
            //             console.log(`e =>`, e);
            //             this.requestStatus = "error";
            //         });
            // }, 300);
        },
    },
};
</script>
<style lang="less">
@import url("../../assets/less/vars.less");
.sto-callback-page {
    width: 60%;
    margin: 0 auto;
    margin-top: 60px;
    @media @sm {
        width: 100%;
        margin-top: 20px;
    }
    .form__content {
        padding-top: 64px;
        @media @sm {
            padding-top: 32px;
        }
    }
}
</style>
